/* eslint-disable quotes */
export const appConfig =
{
  "apiKey": "AIzaSyBtbFmd4VccgJP7-t0Zm6qKV5oZE8Vd9VI",
  "appId": "1:147054906851:web:8dd5df9e0fbfc16eb924d6",
  "authDomain": "schooldog-i-jackson-ga.firebaseapp.com",
  "measurementId": "G-FCGC6GZHRG",
  "messagingSenderId": "147054906851",
  "project": "schooldog-i-jackson-ga",
  "projectId": "schooldog-i-jackson-ga",
  "showGaBranding": true,
  "storageBucket": "schooldog-i-jackson-ga.appspot.com"
}
